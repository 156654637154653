import { makeStyles } from "@material-ui/styles";
import { PRIMARY_ACTIVE_LINK_STYLING } from "../../styles/constants";

export default makeStyles(theme => ({
  signupLink: {
    fontSize: "1.25rem",
    color: theme.palette.primary.main,
    "&:hover, &:focus": {
      ...PRIMARY_ACTIVE_LINK_STYLING,
    },
  },
  forgotPasswordCTAContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  notAUserYetLinkContainer: {
    color: 'blue',
    marginTop: "96px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));
