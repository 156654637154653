import { makeStyles } from "@material-ui/styles";
import { COLOR_BLACK, COLOR_WHITE } from "../../../../../styles/constants";
import { green, orange } from '@material-ui/core/colors';

export default makeStyles(theme => ({
  pricePlanTitle: {
    fontSize: "1.2rem",
    fontWeight: "600",
    [theme.breakpoints.up("lg")]: {
      fontSize: "1.8rem",
    },
  },
  arrowIcon: {
    width: 12.5,
    height: 25,
    marginBottom: theme.spacing(0.5),
  },
  crossIcon: {
    width: 20,
    height: 20,
  },
  planBoxContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center', 
    height: '100%',
    width: '100%',
    borderRadius: theme.spacing(1), 
    transition: '0.3s',
    boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.05)',
    position: 'relative', 
    overflow: 'visible', 
    padding: theme.spacing(1),  
    backgroundColor: theme.palette.grey[100], 
    color: COLOR_BLACK,
    '&:hover': {
      boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.1)', 
      transform: 'translateY(-5px)', 
      backgroundColor: theme.palette.primary.main, 
      color: COLOR_WHITE, 
      '& $title, & $subtitle, & $price, & $fee, & $checkIcon': {
        color: COLOR_WHITE, 
      },
      '& $icon': {
        color: COLOR_WHITE, 
      },
      '& $featureItem': {
        color: COLOR_WHITE,
      },
      '& $priceContainer': {
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
        color: COLOR_WHITE
      },
      '& $mostPopularContainer': { 
        backgroundColor: green[50], 
      },
      '& $mostPopularText': {
          color: COLOR_BLACK,
      },
    },
    maxWidth: 500, 
    [theme.breakpoints.up('md')]: {
      minWidth: 300, 
      maxWidth: 250,
      // left: theme.spacing(20),
    },
    [theme.breakpoints.down('md')]: {
      minWidth: 290, 
      // left: theme.spacing(0),
    },
  },
  mostPopular: {
    position: 'absolute',
    top: 0,
    left: '50%',
    transform: 'translateX(-50%)',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.getContrastText(theme.palette.primary.main),
    padding: theme.spacing(1, 2),
    borderRadius: '0 0 10px 10px',
    fontWeight: 'bold',
    zIndex: 10,
    fontSize: '0.9rem',
    textTransform: 'uppercase',
  },
  icon: {
    fontSize: 40,
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(2),
    transition: '0.3s',
  },
  title: {
    fontWeight: 'bold',
    fontSize: '1.5rem',
    marginBottom: theme.spacing(1),
    transition: '0.3s',
  },
  subtitle: {
    fontSize: '1rem',
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(3),
    transition: '0.3s',
  },
  priceContainer: {
    backgroundColor: COLOR_WHITE,
    boxShadow: '0px 0px 20px 5px rgba(0, 0, 0, 0.08)',
    border: '1px solid',
    borderColor: COLOR_WHITE,
    borderRadius: 10,
    width: '85%', 
    padding: theme.spacing(2, 0), 
    margin: `${theme.spacing(4)}px auto`,
    textAlign: 'center',
    transition:
      'background-color 0.3s ease-in-out, border-color 0.3s ease-in-out, color 0.3s ease-in-out',
  },
  price: {
    fontSize: '2rem',
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
    transition: '0.3s',
  },
  priceDetails: {
    display: 'flex', 
    justifyContent: 'center', 
    alignItems: 'center', 
    gap: '0.5rem',
    color: COLOR_BLACK,
  },
  fee: {
    fontSize: '0.8rem',
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(3),
    transition: '0.3s',
  },
  features: {
    marginBottom: theme.spacing(3),
  },
  featureItem: { 
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
    color: COLOR_BLACK,
    whiteSpace: 'nowrap',  
    overflow: 'hidden',   
    textOverflow: 'ellipsis', 
  },
  checkIcon: {
    color: green[500],
    marginRight: theme.spacing(1),
    transition: '0.3s',
  },
  popular: {
    '&:hover': {
      '& $mostPopular': {
        backgroundColor: theme.palette.getContrastText(
          theme.palette.primary.main,
        ),
        color: theme.palette.primary.main,
      },
    },
  },
  notPopular: {},
  arrowIcon: {
    width: 12.5,
    height: 25,
    marginBottom: theme.spacing(0.5),
  },
  crossIcon: {
    width: 15,
    height: 15,
  },
  closeIconContainer: {
    position: "absolute",  
    top: theme.spacing(1), 
    right: theme.spacing(1),
    cursor: "pointer",
    '& svg': { 
      fill: theme.palette.text.primary, 
      fontSize: '1.5rem', 
      transition: 'fill 0.3s ease',
    },
    '&$disabled svg': {
      fill: theme.palette.grey[400],
      cursor: 'default',
    }
  },
  disabled: {}, 
  mostPopularContainer: {
    backgroundColor: orange[900], 
    color: COLOR_WHITE, 
    padding: theme.spacing(0.2, 1.2), 
    display: 'flex',
    alignItems: 'center',
   },
   mostPopularText: {
    color: COLOR_WHITE,
  },
}));