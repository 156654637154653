import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  Fragment,
} from "react";
import clsx from "clsx";
import { useLocation, useHistory, NavLink } from "react-router-dom";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import MenuIcon from "@material-ui/icons/Menu";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Paper from "@material-ui/core/Paper";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import Container from "@material-ui/core/Container";
import { Select } from "@material-ui/core";
import { connect, useSelector } from "react-redux";
import { debounce } from "lodash";

import { companyLogo, phoneIcon, loginIcon, logoutIcon } from "../../resources/assets";
import SideBar from "../SideBar";
import { isNotShowHeaderPage } from "../../utils";
import * as TopicAction from "../../actions/topicAction";
import styles from "./styles";
import usePath from "../../hooks/usePath";
import {
  COURSE_APP_SUB_ROUTE,
  COURSE_APP_PAGE_ID,
  LOGIN_URL,
  SUBSCRIPTIONS_URL,
  PRICING_URL,
} from "../../routes/constants";
import { courseAppNavLinks, socialMediaIcons, userTabs } from "./constants";
import NavContainer from "../Container/NavContainer";
import { checkIsUserDetailTabPage } from "./helper";
import { CustomMenuItem } from "../MenuElements";
import SelectInput from "./components/SelectInput";
import CourseAppNavLink from "./components/CourseAppNavLink";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faUser,
  faShoppingBag,
} from "@fortawesome/free-solid-svg-icons";
import AppDrawer from "./components/AppDrawer";
import { selectUserAuthToken } from "../../selectors/user";
import { useDispatch } from "react-redux";
import { logout } from "../../actions/userAction";
import { Box, IconButton, Icon, Typography } from "@material-ui/core";
import { SUBSCRIPTION_STATUS } from "../../constants";

const useStyles = makeStyles(theme => styles(theme));

const NavBar = ({ openSideMenu, setOpenSideMenu, getTopicList }) => {
  const { userInfo } = useSelector(state => state.UserStore);
  const { subscription } = useSelector(state => state.SubscriptionStore);
  const location = useLocation();
  const history = useHistory();
  const theme = useTheme();
  const xsDown = useMediaQuery(theme.breakpoints.down("xs"));
  const mdDown = useMediaQuery(theme.breakpoints.down("md"));
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));
  const pathName = location.pathname;
  const notShowHeader = isNotShowHeaderPage(pathName);
  const { rootPageId, fullPath } = usePath();
  const isCourseAppRoute = useMemo(() => rootPageId === COURSE_APP_PAGE_ID, [
    rootPageId,
  ]);
  const isAuthorized = useSelector(selectUserAuthToken);
  const isUserDetailTabPage = checkIsUserDetailTabPage(fullPath);
  const classes = useStyles({ isCourseAppRoute });
  const [topics, setTopics] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [showTopicsSelection, setshowTopicsSelection] = useState(false);
  const [showAppSideMenu, setShowAppSideMenu] = useState(false);
  const [userTabRoute, setUserTabRoute] = useState(pathName);
  const [username, setUsername] = useState("");
  
  const handleOpenAppSideMenu = () => {
    setShowAppSideMenu(true);
  };
  const handleCloseAppSideMenu = () => {
    setShowAppSideMenu(false);
  };

  const handleChangeUserTabSelect = e => {
    setUserTabRoute(e.target.value);
    history.push(e.target.value);
  };

  const fetchTopicsByKeyword = useCallback(
    async value => {
      const filter = {
        keyword: value,
      };

      const res = await getTopicList(filter);
      if (res && res.lessons) {
        setTopics(res.lessons);
      }
    },
    [getTopicList]
  );

  const debouncedSearchTopics = useCallback(
    debounce(value => {
      fetchTopicsByKeyword(value);
    }, 300),
    [fetchTopicsByKeyword]
  );

  const onSearchKeywordChange = useCallback(
    value => {
      setSearchKeyword(value);
      debouncedSearchTopics(value);
    },
    [debouncedSearchTopics]
  );

  const toggleSideMenu = () => {
    setOpenSideMenu(!openSideMenu);
  };

  useEffect(() => {
    if (userInfo) {
      setUsername(userInfo.firstName + ' ' + userInfo.lastName)
    } 
  }, [userInfo]);

  useEffect(() => {
    if (!searchKeyword) {
      setTopics([]);
    }
  }, [searchKeyword]);

  useEffect(() => {
    if (subscription && subscription.status === SUBSCRIPTION_STATUS.PENDING) {
      history.push(PRICING_URL)
    } 
  }, [subscription]);

  useEffect(() => {
    if (pathName.substr(0, 15) !== `${COURSE_APP_SUB_ROUTE}/topics/`) {
      setSearchKeyword("");
    }
  }, [pathName]);

  useEffect(() => {
    if (mdDown) setOpenSideMenu(false);
  }, [mdDown]);

  if (notShowHeader) return null;

  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(logout());
    history.push(LOGIN_URL);
  };

  return (
    <Fragment>
      {/* Contact NavBar */}
      {!isCourseAppRoute && (
        <AppBar className={classes.topBar} color="inherit" position="sticky">
          <Container fixed maxWidth={false} style={{ height: "100%" }}>
            <Box
              display="flex"
              height="100%"
              width="100%"
              justifyContent="space-between"
            >
              <Box display="flex" justifyContent="center" alignItems="center">
                <img
                  className={classes.topBarIcon}
                  src={phoneIcon}
                  alt="phone icon"
                />
                <Box ml={1.5} color="#fff" className={classes.phoneLabel}>
                  0844 544 4804
                </Box>
              </Box>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="center"
                alignItems="center"
              >
                {socialMediaIcons.map(itm => (
                  <Box ml={1.5} key={itm.alt}>
                    <img
                      className={classes.topBarIcon}
                      src={itm.imgPath}
                      alt={itm.alt}
                    />
                  </Box>
                ))}
              </Box>
            </Box>
          </Container>
        </AppBar>
      )}
      <AppBar className={classes.appBar} color="inherit" position="sticky">
        {/* Course App Page NavBar */}
        {isCourseAppRoute ? (
          <Toolbar>
            <div onClick={toggleSideMenu} style={{ cursor: "pointer" }}>
              <MenuIcon />
            </div>
            <div className={classes.courseNavContentContainer}>
              {mdUp && (
                <Box display="flex" alignItems={"center"}>
                  <img
                    src={companyLogo}
                    alt="Logo of AMCKEY"
                    className={classes.logo}
                  />
                  <Box className={classes.title} mt={1}>
                    Welcome {username}
                  </Box>
                </Box>
              )}

              <div
                className={clsx(
                  classes.searchContainer,
                  showTopicsSelection &&
                    topics.length > 0 &&
                    classes.searchAutoComplete
                )}
              >
                <div className={classes.searchIcon}>
                  <SearchIcon />
                </div>
                <Autocomplete
                  style={{ width: "100%" }}
                  open={showTopicsSelection}
                  onOpen={() => {
                    setshowTopicsSelection(true);
                  }}
                  onClose={() => {
                    setshowTopicsSelection(false);
                  }}
                  inputValue={searchKeyword}
                  onInputChange={(event, newInputValue) => {
                    onSearchKeywordChange(newInputValue);
                  }}
                  freeSolo={true}
                  filterOptions={(options, state) => options}
                  options={topics}
                  classes={{
                    listbox: classes.autoCompleteListBoxCont,
                    option: classes.autoCompleteOptionCont,
                    endAdornment: classes.autoCompleteEndAdornment,
                    clearIndicator: classes.autoCompleteClearIndicator,
                  }}
                  getOptionLabel={option => (option ? option.title : "")}
                  renderOption={topic => {
                    return (
                      <div
                        onClick={() => {
                          setSearchKeyword(topic.title);
                          history.push(
                            `${COURSE_APP_SUB_ROUTE}/topics/${topic._id}`
                          );
                        }}
                        className={classes.autoCompleteOptionTxtCont}
                      >
                        <DescriptionOutlinedIcon
                          style={{ width: 16, height: 16 }}
                        />
                        <span style={{ marginLeft: 8, fontSize: 16 }}>
                          {topic.title}
                        </span>
                      </div>
                    );
                  }}
                  PaperComponent={props => {
                    return topics.length > 0 ? (
                      <Paper
                        elevation={0}
                        className={classes.autoCompletePaper}
                      >
                        {props.children}
                      </Paper>
                    ) : null;
                  }}
                  renderInput={params => {
                    const { InputLabelProps, InputProps, ...rest } = params;
                    return (
                      <InputBase
                        {...params.InputProps}
                        {...rest}
                        placeholder="Search Topic"
                        classes={{
                          root: classes.inputRoot,
                          input: classes.inputInput,
                        }}
                      />
                    );
                  }}
                />
              </div>
              <Box className={classes.courseAppIconContainer}>
                {courseAppNavLinks.map(item => (
                  <CourseAppNavLink
                    key={item.title}
                    icon={item.icon}
                    title={item.title}
                    route={item.route}
                  />
                ))}
              </Box>
            </div>
          </Toolbar>
        ) : (
          <>
            {/* App Page NavBar */}
            <Toolbar disableGutters>
              <NavContainer>
                <Box
                  display="flex"
                  height="100%"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Box>
                    <img
                      src={companyLogo}
                      alt="Logo of AMCKEY"
                      className={classes.homeLogo}
                    />
                  </Box>
                  <Box display="flex" alignItems="center">
                    <Box
                      mr={3}
                      className={classes.appIconLink}
                      onClick={() =>
                        history.push(
                          isAuthorized ? SUBSCRIPTIONS_URL : LOGIN_URL
                        )
                      }
                    >
                      <FontAwesomeIcon
                        icon={faUser}
                        color={theme.palette.primary.main}
                      />
                    </Box>
                    <Box
                      mr={3}
                      className={classes.appIconLink}
                      onClick={() => history.push(PRICING_URL)}
                    >
                      <FontAwesomeIcon
                        icon={faShoppingBag}
                        color={theme.palette.primary.main}
                      />
                    </Box>
                    <Box onClick={isAuthorized ? handleLogout : () => history.push(LOGIN_URL)}>
                      <img
                        className={isAuthorized ? classes.logoutIcon: classes.loginIcon}
                        src={isAuthorized ? logoutIcon : loginIcon} 
                      />
                    </Box>
                  </Box>
                </Box>
              </NavContainer>
            </Toolbar>
          </>
        )}
      </AppBar>
      {/* App Page Drawer*/}
      <AppDrawer open={showAppSideMenu} onClose={handleCloseAppSideMenu} />

      {isUserDetailTabPage && (
        <Box
          py={2}
          className={classes.tabsBar}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          {xsDown ? (
            <Select
              value={userTabRoute}
              onChange={handleChangeUserTabSelect}
              className={classes.tabsSelect}
              variant="outlined"
              input={<SelectInput />}
            >
              {userTabs.map((item, index) => (
                <CustomMenuItem value={item.route}>{item.title}</CustomMenuItem>
              ))}
            </Select>
          ) : (
            <Box display="flex" justifyContent="center" alignItems="center">
              {userTabs.map((item, index) => (
                <Box
                  key={item.title}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  className={clsx(fullPath === item.route && classes.arrowBox)}
                >
                  <Box
                    px={3}
                    key={item.title}
                    className={clsx(
                      index !== userTabs.length - 1 && classes.tabLinkContainer
                    )}
                  >
                    <NavLink
                      exact
                      className={classes.tabLink}
                      activeClassName={classes.activeTabLink}
                      to={item.route}
                      onClick={() => setUserTabRoute(item.route)}
                    >
                      {item.title}
                    </NavLink>
                  </Box>
                </Box>
              ))}
            </Box>
          )}
        </Box>
      )}

      {isCourseAppRoute && (
        <SideBar
          setOpenSideMenu={setOpenSideMenu}
          openSideMenu={openSideMenu}
        />
      )}
    </Fragment>
  );
};

const mapStateToProps = ({}) => ({});

const mapDispatchToProps = dispatch => ({
  getTopicList: filter => dispatch(TopicAction.getTopicList(filter)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
