import React, { useMemo } from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Typography from "@material-ui/core/Typography"; 

import useStyles from "./styles";
import { CURRENCY_CODE, CURRENCY_FORMAT } from "../../../../../constants";

const TotalPriceBox = props => {
  const { cartItems, handleBack, handleNext } = props;
  const theme = useTheme();
  const lgUp = useMediaQuery(theme.breakpoints.up("lg"));
  const classes = useStyles();

  const grandTotal = useMemo(() => {
    if (cartItems.length > 0) {
      return cartItems.reduce((acc, curr) => acc + curr.price, 0);
    } else {
      return 0;
    }
  }, [cartItems]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      width="100%"
      maxWidth={300}
      margin="0 auto"
    >
      <Box className={classes.totalPriceBox}>
        <Box className={classes.priceContainer}>
          <Typography variant="body2" component="span" className={classes.totalLabel}>
            Total ({CURRENCY_CODE})
          </Typography>
          <Typography variant="h5" component="span" className={classes.totalPrice}>
            {CURRENCY_FORMAT} {grandTotal}
          </Typography>
        </Box>

        <Button
          color="primary"
          variant="contained"
          className={classes.checkoutButton}
          onClick={handleNext}
        >
          Checkout
        </Button>
      </Box>
      <Box
        fontSize="1rem"
        onClick={handleBack}
        className={classes.continueTxt}
      >
        Continue Shopping
      </Box>
    </Box>
  );
};

export default TotalPriceBox;