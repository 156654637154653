import React, { Fragment, useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import clsx from "clsx";
import Drawer from "@material-ui/core/Drawer";
import Box from "@material-ui/core/Box";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import FeedbackIcon from "@material-ui/icons/Feedback";
import SwapHorizIcon from "@material-ui/icons/SwapHoriz";
import ConfirmationNumberOutlinedIcon from "@material-ui/icons/ConfirmationNumberOutlined";
import DeleteIcon from "@material-ui/icons/Delete";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

import { userMenuItemArr, adminMenuItemArr } from "./sideBarArr";
import SubSideBar from "../SubSideBar";
import { DRAWER_WIDTH } from "../../styles/constants";
import { formatDateWithDefaultTimeZone } from "../../utils";
import {
  LOGIN_URL,
  MANAGE_DELETED_ITEM_URL,
  MANAGE_DISCOUNT_CODE_URL,
  MANAGE_FEEDBACK_URL,
  MANAGE_TRANSACTION_URL,
  PERFORMANCE_OVERALL_URL,
  PRICING_URL,
  TEST_LIST_URL,
  TOPICS_URL,
} from "../../routes/constants";
import useStyles from "./styles";
import { shouldDisabledLink } from "./helper";
import PrimaryConfirmationModal from "../Modal/PrimaryConfirmationModal";
import useModal from "../../hooks/useModal";
import { clearTopicListState } from "../../actions/topicAction";

const SIDEBAR_ICON_COLOR = "rgb(153, 167, 179)";

const SideBar = ({ openSideMenu, setOpenSideMenu }) => {
  const dispatch = useDispatch();

  const [expand, setExpand] = useState(false);
  const [showMainSideBar, setShowMainSideBar] = useState(false);
  const { type: userType, userInfo } = useSelector(state => state.UserStore);
  const isAdmin = userType === "admin";
  const { subscription } = useSelector(state => state.SubscriptionStore);
  const history = useHistory();
  const location = useLocation();
  const pathName = location.pathname;
  const showSideBar = pathName !== LOGIN_URL;
  const isTopicDetailPage = pathName.match(/\/topics\/[abc|0-9]+/);
  const notShowMainSideBar = isTopicDetailPage && !showMainSideBar;
  const classes = useStyles({
    notShowMainSideBar,
  });


  const shouldOpenExpiredWarning =
    subscription && !subscription.isValid && subscription.planEndOn;

  const toggleExpand = () => {
    setExpand(!expand);
    history.push(PERFORMANCE_OVERALL_URL);
  };

  const handleNavigate = route => {
    if (route === TOPICS_URL) {
      dispatch(clearTopicListState());
    }
    history.push(route);
    setExpand(false);
  };

  const hideMainSideBar = () => {
    setShowMainSideBar(false);
  };

  const handleCloseDrawer = () => {
    setOpenSideMenu(false);
  };
  const handleOpenDrawer = () => {
    setOpenSideMenu(true);
  };

  const {
    open: openInvalidUserModal,
    handleOpen: handleOpenInvalidUserModal,
    handleClose: handleCloseInvalidUserModal,
  } = useModal();

  const handleNavigateToPricingPage = () => {
    history.push(PRICING_URL);
  };

  useEffect(() => {
    if (isTopicDetailPage) {
      setOpenSideMenu(true);
    }
    if (pathName === TEST_LIST_URL) setOpenSideMenu(false);
  }, [pathName]);

  if (!showSideBar) return null;

  return (
    <Fragment>
      <PrimaryConfirmationModal
        open={openInvalidUserModal}
        onClose={handleCloseInvalidUserModal}
        actionFn={handleNavigateToPricingPage}
        ctaTitle={
          "Your subscription has expired, do you want to renew your subscription?"
        }
        ctaTxt={"Yes"}
        secondaryTxt={"No"}
      />
      <div className={classes.container}>
        <div
          className={classes.chevronContainer}
          style={
            openSideMenu
              ? {
                  transition: "all 225ms cubic-bezier(0, 0, 0.2, 1) 0ms",
                  left: DRAWER_WIDTH - 10,
                }
              : {
                  transform: "translateX(-12px)",
                  transition: "all 225ms cubic-bezier(0, 0, 0.2, 1) 0ms",
                  left: 1,
                }
          }
        >
          {openSideMenu ? (
            <ChevronLeftIcon
              className={clsx(classes.chveron)}
              onClick={handleCloseDrawer}
            />
          ) : (
            <ChevronRightIcon
              className={clsx(classes.chveron)}
              onClick={handleOpenDrawer}
            />
          )}
        </div>

        {openSideMenu && (
          <Drawer
            variant="persistent"
            open={openSideMenu}
            classes={{
              paper: clsx(
                classes.drawerPaper,
                !openSideMenu && classes.drawerPaperClose
              ),
              root: classes.drawerRoot,
            }}
          >
            <SubSideBar
              setShowMainSideBar={setShowMainSideBar}
              isTopicDetailPage={isTopicDetailPage}
              notShowMainSideBar={isTopicDetailPage && !showMainSideBar}
            />
            <List
              // onTouchCancel={hideMainSideBar}
              // onTouchEnd={hideMainSideBar}
              onMouseLeave={hideMainSideBar}
              className={classes.mainSideBarList}
            >
              {userMenuItemArr.map(list => {
                const disabled = isAdmin
                  ? false
                  : userInfo &&
                    subscription &&
                    shouldDisabledLink(list.route, userInfo, subscription);

                return list.subLink ? (
                  <Fragment key={list.title}>
                    <div
                      className={clsx(
                        !disabled && classes.performanceLinkContainer,
                        expand &&
                          !disabled &&
                          classes.performanceActiveLinkContainer
                      )}
                    >
                      <ListItem
                        disabled={disabled}
                        className={clsx(
                          !disabled && classes.performanceTitle,
                          expand && !disabled && classes.performanceActiveTitle
                        )}
                        onClick={() => {
                          if (!disabled) {
                            toggleExpand();
                          } else {
                            if (shouldOpenExpiredWarning) {
                              handleOpenInvalidUserModal();
                            }
                          }
                        }}
                      >
                        <ListItemIcon>
                          <img
                            src={list.imgPath}
                            alt={list.alt}
                            className={classes.icon}
                          />
                        </ListItemIcon>
                        <ListItemText primary={list.title} />
                        {expand ? (
                          <ExpandLess />
                        ) : (
                          <ExpandMore style={{ color: "#8991a1" }} />
                        )}
                      </ListItem>
                      <Collapse in={expand} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                          {list.subLink.map(sublink => (
                            <div
                              className={clsx(openSideMenu && classes.nested)}
                              key={sublink.title}
                            >
                              <ListItem
                                className={clsx(
                                  classes.performanceTitle,
                                  pathName === sublink.route &&
                                    classes.performanceActiveTitle
                                )}
                                onClick={() => history.push(sublink.route)}
                              >
                                <ListItemIcon>
                                  <img
                                    src={sublink.imgPath}
                                    alt={sublink.alt}
                                    className={classes.icon}
                                  />
                                </ListItemIcon>
                                <ListItemText primary={sublink.title} />
                              </ListItem>
                            </div>
                          ))}
                        </List>
                      </Collapse>
                    </div>
                  </Fragment>
                ) : (
                  <div
                    className={clsx(
                      !disabled && classes.linkContainer,
                      pathName === list.route &&
                        !disabled &&
                        classes.activeLinkContainer
                    )}
                    key={list.title}
                  >
                    <ListItem
                      disabled={disabled}
                      onClick={() => {
                        if (!disabled) {
                          handleNavigate(list.route);
                        } else {
                          if (shouldOpenExpiredWarning) {
                            handleOpenInvalidUserModal();
                          }
                        }
                      }}
                    >
                      <ListItemIcon>
                        <img
                          src={list.imgPath}
                          alt={list.alt}
                          className={classes.icon}
                        />
                      </ListItemIcon>
                      <ListItemText primary={list.title} />
                    </ListItem>
                  </div>
                );
              })}

              {isAdmin &&
                adminMenuItemArr.map(list => (
                  <div
                    className={clsx(
                      classes.linkContainer,
                      pathName === list.route && classes.activeLinkContainer
                    )}
                    key={list.title}
                  >
                    <ListItem onClick={() => handleNavigate(list.route)}>
                      <ListItemIcon>
                        {list.route === MANAGE_FEEDBACK_URL ? (
                          <FeedbackIcon htmlColor={SIDEBAR_ICON_COLOR} />
                        ) : list.route === MANAGE_TRANSACTION_URL ? (
                          <SwapHorizIcon
                            style={{ width: 30, height: 30 }}
                            htmlColor={SIDEBAR_ICON_COLOR}
                          />
                        ) : list.route === MANAGE_DISCOUNT_CODE_URL ? (
                          <ConfirmationNumberOutlinedIcon
                            style={{ width: 25, height: 25 }}
                            htmlColor={SIDEBAR_ICON_COLOR}
                          />
                        ) : list.route === MANAGE_DELETED_ITEM_URL ? (
                          <DeleteIcon htmlColor={SIDEBAR_ICON_COLOR} />
                        ) : (
                          <img
                            src={list.imgPath}
                            alt={list.alt}
                            className={classes.icon}
                          />
                        )}
                      </ListItemIcon>
                      <ListItemText primary={list.title} />
                    </ListItem>
                  </div>
                ))}
            </List>

            {Boolean(userInfo) &&
              Boolean(subscription) &&
              Boolean(subscription.planEndOn) && (
                <Box className={classes.footCont}>
                  <Box fontSize={15} fontWeight={600}>
                    Expiration Date
                  </Box>
                  <Box fontSize={12}>
                    {formatDateWithDefaultTimeZone(subscription.planEndOn)}
                  </Box>
                  {userInfo.isBlocked && (
                    <Box pt={1} fontSize={12}>
                      {`Your account has been
  suspended until ${formatDateWithDefaultTimeZone(userInfo.blockedEndDate)}`}
                    </Box>
                  )}
                </Box>
              )}
          </Drawer>
        )}
      </div>
    </Fragment>
  );
};

export default SideBar;
