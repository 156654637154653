import React, {
    useState,
} from 'react';
import { Typography, Box, Link, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile'; 
import { newUpdate } from "../../resources/assets";
import { useSelector } from "react-redux";
import { SUBSCRIPTION_STATUS } from "../../constants";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
    introContainer: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
        padding: theme.spacing(3),
        border: `1px solid ${theme.palette.grey[300]}`,
        borderRadius: theme.shape.borderRadius,
    },
    title: {
        fontWeight: 700,
        marginBottom: theme.spacing(2),
    },
    pdfList: {
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(4),
        padding: 0,
    },
    pdfListItem: {
        padding: theme.spacing(1, 0), 
        borderBottom: `1px solid ${theme.palette.grey[200]}`,
        '&:last-child': {  
            borderBottom: 'none',
        },
    },
    pdfLink: {
        color: 'blue',
        textDecoration: 'none',
        display: 'flex',
        alignItems: 'center', 
        '&:hover': {
            textDecoration: 'underline',
            color: theme.palette.primary.main,
        },
    },
    pdfIcon: { 
        marginRight: theme.spacing(1), 
        fontSize: '1.2rem',    
    },
    bannerImage: { 
        width: '100%', 
        maxWidth: 400, 
        marginBottom: theme.spacing(3),
        borderRadius: theme.shape.borderRadius, 

    },
}));

const WelcomePage = () => {
    const classes = useStyles();
    const { subscription } = useSelector(state => state.SubscriptionStore);
    const [openTrialDialog, setOpenTrialDialog] = useState(false);
    const pdfLinks = [ 
        "https://qbank.amckey.com/pdfs/BNF for Children (BNFC) 2019-2020.pdf",
        "https://qbank.amckey.com/pdfs/eTG-Australian Therapeutic Guidelines  2020 (Part 1).pdf",
        "https://qbank.amckey.com/pdfs/eTG-Australian Therapeutic Guidelines  2020 (Part 2).pdf",
        "https://qbank.amckey.com/pdfs/eTG-Australian Therapeutic Guidelines  2020 (Part 3).pdf",
        "https://qbank.amckey.com/pdfs/Guidelines for preventive activities in general practice.pdf",
        "https://qbank.amckey.com/pdfs/Kaplan 2020 Gynae Obs.pdf",
        "https://qbank.amckey.com/pdfs/Kaplan 2020 Medicine.pdf",
        "https://qbank.amckey.com/pdfs/Kaplan 2020 Paediatrics.pdf",
        "https://qbank.amckey.com/pdfs/Kaplan Obstetrics n Gynecology.pdf",
        "https://qbank.amckey.com/pdfs/Kaplan Pediatrics.pdf",
        "https://qbank.amckey.com/pdfs/Kaplan Psychiatry Epidemiology and Ethics.pdf",
        "https://qbank.amckey.com/pdfs/Kaplan Surgery P 1 2020.pdf",
        "https://qbank.amckey.com/pdfs/Kaplan Surgery P 2 2020.pdf",
        "https://qbank.amckey.com/pdfs/Master the Boards Step 2 CK 4th Edition epub converted pdf-1.pdf",
        "https://qbank.amckey.com/pdfs/Murtagh’s General Practice 7th edition.pdf",
        "https://qbank.amckey.com/pdfs/Murtagh's Practice Tips 6th Edition.pdf.pdf",
        "https://qbank.amckey.com/pdfs/STEP 2 CK 2018 USMLE Internal.pdf"
    ];

    const handleCloseTrialDialog = () => {
        setOpenTrialDialog(false);
    };

    const isFreeTrial = subscription && subscription.status === SUBSCRIPTION_STATUS.TRIAL

    const handlePdfLinkClick = (event, link) => {
        if (isFreeTrial) {
          event.preventDefault();
          setOpenTrialDialog(true); 
        }
    };

    return (
        <Box className={classes.introContainer}>
            <img src={newUpdate} alt="Welcome Banner" className={classes.bannerImage} />
            <Typography variant="h5" className={classes.title}>
                "Exciting news! We've just launched our brand new AI Chatbot (Beta)! <br />
                Get instant answers and personalized support, available anytime you need it."
            </Typography>
            <Typography variant="body1" paragraph>
                To help you get the most out of our AI Chatbot, we've also provided a comprehensive Knowledge Base.<br />  
                Click the links below to access the PDFs containing the information our Chatbot draws from: 
            </Typography>

            <Dialog open={openTrialDialog} onClose={handleCloseTrialDialog}>
                <DialogContent>Free trial users cannot access the PDF knowledge base.</DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseTrialDialog} color="primary">
                        OK
                    </Button>

                </DialogActions>
            </Dialog>

            <List className={classes.pdfList}>
                {pdfLinks.map((pdfLink, index) => ( 
                <ListItem
                    key={index}
                    className={classes.pdfListItem}
                    disableGutters
                >
                    <ListItemIcon>
                        <InsertDriveFileIcon className={classes.pdfIcon} />
                    </ListItemIcon>

                    <Link
                        component="a"
                        href={pdfLink} 
                        target="_blank"
                        rel="noopener noreferrer"
                        className={classes.pdfLink}
                        onClick={(event) => handlePdfLinkClick(event, pdfLink)}
                    >
                    <ListItemText
                        primary={pdfLink.substring(pdfLink.lastIndexOf('/') + 1)}
                    />
                    </Link>
                </ListItem>
                ))}
            </List>
        </Box>
    );
};

export default WelcomePage;
