import { Box, Button, Typography } from "@material-ui/core"; 
import React, { useMemo } from "react";
import useStyles from "./styles";
import {
  CURRENCY_CODE,
  CURRENCY_FORMAT,
  TRANSACTION_SOURCE,
} from "../../../../constants"; 
import { useDispatch, useSelector } from "react-redux";
import { selectUserAuthToken } from "../../../../selectors/user"; 
import { parseAuthToken } from "../../../../utils"; 
import { updateUserProfile } from "../../../../actions/userAction"; 
import {
  subscribePlanWithPaypal,
  subscribePlanWithStripe,
} from "../../../../actions/subscriptionAction";  
import DiscountCodeInput from "./DiscountCodeInput"; 
import { selectVoucherAvailability } from "../../../../selectors/voucher"; 
import { checkIsFreeTrialPlan, getGrandTotalAndGrandDiscount } from "../helper"; 
import {
  selectIsValidToPay,
  selectPaymentMethod,
} from "../../../../selectors/cart"; 
import { setIsValidToPay } from "../../../../actions/cartAction"; 
import { useForm } from "react-hook-form";
import { setVoucherAvailability } from "../../../../actions/voucherAction";
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

const OrderSummary = ({ cartItems, handleOpenRemoveModal, handleSubmit }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const authToken = useSelector(selectUserAuthToken);
  const voucherAvailability = useSelector(selectVoucherAvailability);
  const paymentMethod = useSelector(selectPaymentMethod);
  const isValidToPay = useSelector(selectIsValidToPay);

  const subTotal = useMemo(() => {
    if (cartItems.length > 0) {
      return cartItems.reduce((acc, curr) => acc + curr.price, 0);
    } else {
      return 0;
    }
  }, [cartItems]);

  const isVoucherApplied = voucherAvailability && voucherAvailability.valid;
  const discountUnit = isVoucherApplied ? voucherAvailability.unit : null;
  const discountAmount = isVoucherApplied ? voucherAvailability.discount : null;
  const { grandTotal, totalDiscount } = getGrandTotalAndGrandDiscount(
    subTotal,
    discountUnit,
    discountAmount
  );
  const showCTA = !isValidToPay && authToken;
  const isFreeTrialPlan = checkIsFreeTrialPlan(cartItems);

  const discountCodeInputMethods = useForm({
    mode: "onChange",
    defaultValues: {
      voucherCode: "",
    },
  });

  const onProceedCheckout = data => {
    if (authToken) {
      if (!isVoucherApplied) {
        discountCodeInputMethods.setValue("voucherCode", "");
        dispatch(setVoucherAvailability(null));
      }
      const userId = parseAuthToken(authToken);

      const updateProfileData = {
        _id: userId,
        input: { ...data },
      };

      const selectedPricePlan = cartItems[0];
      if (selectedPricePlan) {
        if (isFreeTrialPlan) {
          dispatch(updateUserProfile(updateProfileData));
          dispatch(setIsValidToPay(true));
        } else {
          const input = {
            plan_id: selectedPricePlan.plan_id,
            pricePlanID: selectedPricePlan.pricePlanID,
            voucherCode: isVoucherApplied
              ? voucherAvailability.code
              : undefined,
          };

          if (paymentMethod === TRANSACTION_SOURCE.PAYPAL) {
            dispatch(subscribePlanWithPaypal({ input }, updateProfileData));
          } else if (paymentMethod === TRANSACTION_SOURCE.STRIPE) {
            dispatch(subscribePlanWithStripe({ input }, updateProfileData));
          }
        }
      }
    }
  };


  return (
    <Box className={classes.orderSummary}>
      <Typography variant="h5" component="h2" className={classes.orderSummaryTitle}>
        Order Summary
      </Typography>

      <Box className={classes.itemsList}>
        {cartItems.map((item, index) => (
          <Box key={index} className={classes.itemRow}>
            <Box display="flex" alignItems="center" flexGrow={1}>
              <Box ml={2}>
                <Typography variant="subtitle1" className={classes.itemTitle}>
                  {item.planName}
                </Typography>
                <Typography variant="body2" className={classes.itemSubtitle}>
                  {item.title}
                </Typography>
              </Box>
            </Box>
            <Box display="flex" alignItems="center">
              <Typography variant="subtitle1" className={classes.itemPrice}>
                {`${CURRENCY_FORMAT} ${item.price.toFixed(2)}`}
              </Typography>
              <IconButton
                onClick={handleOpenRemoveModal}
                className={classes.removeButton}
                aria-label="Remove item"
              >
                <DeleteIcon /> 
              </IconButton>
            </Box>
          </Box>
        ))}
      </Box>

      <Box className={classes.summarySection}>
        <Typography variant="h6" component="h3" className={classes.summaryTitle}>
          Summary ({CURRENCY_CODE})
        </Typography>

        <Box className={classes.paramsRow}>
          <Typography variant="body1">Subtotal</Typography>
          <Typography variant="body1" className={classes.priceValue}>
            {`${CURRENCY_FORMAT} ${subTotal.toFixed(2)}`}
          </Typography>
        </Box>

        {isVoucherApplied && (
          <Box className={classes.paramsRow}>
            <Typography variant="body1">Discount</Typography>
            <Typography variant="body1" className={classes.priceValue}>
              {`${CURRENCY_FORMAT} -${totalDiscount.toFixed(2)}`}
            </Typography>
          </Box>
        )}
      </Box>

      {authToken && !isFreeTrialPlan && (
        <DiscountCodeInput
          isValidToPay={isValidToPay}
          {...discountCodeInputMethods}
        />
      )}


      <Box className={classes.totalRow}>
        <Typography variant="h6" component="h3">
          Total
        </Typography>
        <Typography variant="h6" className={classes.totalPriceValue}>
          {`${CURRENCY_FORMAT} ${grandTotal}`}
        </Typography>
      </Box>

      {showCTA && (
        <Button
          color="primary"
          variant="contained"
          className={classes.checkoutButton}
          onClick={handleSubmit(onProceedCheckout)}
          fullWidth
        >
          Checkout
        </Button>
      )}
    </Box>
  );
};

export default OrderSummary;