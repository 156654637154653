import { makeStyles } from "@material-ui/styles";
import { COLOR_WHITE } from "../../../../styles/constants"; 
import { PAYMENY_SECTION_BUTTON_STYLING } from "../../../../styles/constants";

export default makeStyles(theme => ({
  orderSummary: {
    backgroundColor: COLOR_WHITE,
    borderRadius: theme.shape.borderRadius, 
    boxShadow: theme.shadows[1], 
    padding: theme.spacing(3), 
    width: '100%', 
    maxWidth: 400, 
    margin: '0 auto',
  },
  orderSummaryTitle: {
    fontWeight: 600,
    marginBottom: theme.spacing(2),
    color: theme.palette.text.primary, 
  },
  itemsList: {
    marginBottom: theme.spacing(3),
  },
  itemRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(1, 0),
    borderBottom: `1px solid ${theme.palette.grey[200]}`, 
    '&:last-child': {
      borderBottom: 'none', 
    },
  },
  itemImage: {
    width: 60,
    height: 60,
    borderRadius: theme.shape.borderRadius,
    objectFit: 'cover', 
  },
  itemTitle: {
    fontWeight: 600,
    color: theme.palette.text.primary,
  },
  itemSubtitle: {
    color: theme.palette.text.secondary, 
  },
  itemPrice: {
    fontWeight: 600,
    color: theme.palette.text.primary,
    marginRight: theme.spacing(1), 
  },
  removeButton: {
    padding: theme.spacing(1),
  },
  summarySection: {
    marginBottom: theme.spacing(2),
  },
  summaryTitle: {
    fontWeight: 600,
    marginBottom: theme.spacing(1),
    color: theme.palette.text.primary,
  },
  paramsRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
  priceValue: {
    color: theme.palette.text.primary,
    fontWeight: 500,
  },
  totalRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: theme.spacing(2),
    borderTop: `2px solid ${theme.palette.grey[200]}`, 
  },
  totalPriceValue: {
    color: theme.palette.primary.main,
    fontWeight: 700,
  },
  checkoutButton: {
    marginTop: theme.spacing(3),
     padding: theme.spacing(1.5, 0), 
    fontSize: "1rem", 
    textTransform: "none",
    fontWeight: 600,
  },
  paymentMethodIcon: {
    objectFit: "contain",
    width: "fit-content",
  },
  line: {
    borderTop: `1px solid ${theme.palette.primary.main}`,
    width: "100%",
  },
  button: {
    ...PAYMENY_SECTION_BUTTON_STYLING,
  },
  discountCodeToggleIcon: {
    fontSize: 20,
    cursor: "pointer",
  },
  applyButton: {
    height: "100% !important",
  },
  discountCodeInputBox: {
    borderTop: "1px solid #d3d3d4",
    borderBottom: "1px solid #d3d3d4",
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
  },
  discountCodeRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  leftPlaceholder: {
    width: 40,
    height: "100%",
  },
  crossIcon: {
    width: 40,
    height: 40,
    cursor: "pointer",
  },
}));
