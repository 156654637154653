import React, { useState, useEffect, useMemo, Fragment } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";

import { DRAWER_WIDTH } from "../../styles/constants";
import { isNotShowHeaderPage, isTestDetailPage } from "../../utils";
import Footer from "../Footer";
import usePath from "../../hooks/usePath";
import { APP_PAGE_ID, COURSE_APP_PAGE_ID, APP_SUB_ROUTE, WELCOME_URL } from "../../routes/constants";
import CenterContainer from "../Container/CenterContainer";
import { MAIN_BAR_HEIGHT } from "../NavBar/constants";
import { checkIsUserDetailTabPage } from "../NavBar/helper";
import useDisableRightClick from "../../hooks/useDisableRightClick";
import FloatingChatButton from '../../Components/FloatingChatButton';
import NoAppContent from "../../views/NoAppContent";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { SUBSCRIPTION_STATUS } from "../../constants";

const NORMAL_MARGIN = 24;

const useStyles = makeStyles(theme => ({
  content: {
    flexGrow: 1,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      marginRight: 0,
    },
    margin: props => (props.isCourseAppRoute ? NORMAL_MARGIN : 0),
    marginBottom: props => (props.isCourseAppRoute ? 100 : 0),
    marginTop: props =>
      props.isCourseAppRoute
        ? NORMAL_MARGIN
        : props.isAppRoute && !props.isUserDetailTabPage
        ? MAIN_BAR_HEIGHT
        : 0,
    paddingTop: props => (props.isAppRoute ? theme.spacing(6) : 0),
    paddingBottom: props => (props.isAppRoute ? theme.spacing(6) : 0),
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: `${DRAWER_WIDTH + 24}px !important`,
  },
}));

const MainLayout = ({ openSideMenu, showAppFooter, children }) => {
  const [shouldShift, setShouldShift] = useState(openSideMenu);

  const { rootPageId, fullPath } = usePath();
  const isCourseAppRoute = useMemo(() => rootPageId === COURSE_APP_PAGE_ID, [
    rootPageId,
  ]);
  const isAppRoute = useMemo(() => rootPageId === APP_PAGE_ID, [rootPageId]);
  const isUserDetailTabPage = checkIsUserDetailTabPage(fullPath);
  const isShouldShfitPage = isNotShowHeaderPage(fullPath);
  const isShouldRemoveContentStylesPage = isTestDetailPage(fullPath);
  const classes = useStyles({
    isCourseAppRoute,
    isAppRoute,
    isUserDetailTabPage,
  });
  const canShowFooter = showAppFooter | isAppRoute;
  const location = useLocation();
  const { subscription } = useSelector(state => state.SubscriptionStore);
  const [isFreeTrial, setSubscriptionPlan] = useState("");

  useEffect(() => {
    if (subscription) {
        setSubscriptionPlan(subscription.status === SUBSCRIPTION_STATUS.TRIAL); 
    }
  }, [subscription]);

  useEffect(() => {
    if (!isShouldShfitPage) setShouldShift(openSideMenu);
    else {
      setShouldShift(false);
    }
  }, [isShouldShfitPage, openSideMenu]);

  useDisableRightClick();

  if (location.pathname === APP_SUB_ROUTE) {  
    return (
      <NoAppContent />
    );
  }
  
  return (
    <Fragment>
      <main
        className={clsx(!isShouldRemoveContentStylesPage && classes.content, {
          [classes.contentShift]: shouldShift && isCourseAppRoute,
        })}
      >
        {isAppRoute ? <CenterContainer>{children}</CenterContainer> : children}
        { 
          isCourseAppRoute && location.pathname === WELCOME_URL && !isFreeTrial 
            ? <FloatingChatButton />
            : null
        }
      </main>
      {canShowFooter ? (
        isCourseAppRoute ? (
          <div
            style={{
              marginLeft: shouldShift ? DRAWER_WIDTH - 180 : 0,
              left: shouldShift ? "" : 0,
              zIndex: 100,
            }}
            className="appFooter"
          >
            <div>
              <span className="fCopyright bold">Copyright</span>
              <span className="fCopyright">© AMCKEY. All rights reserved.</span>
            </div>
          </div>
        ) : isAppRoute ? (
          <Footer />
        ) : null
      ) : null}
    </Fragment>
  );
};

export default MainLayout;
