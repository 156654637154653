import { makeStyles } from "@material-ui/styles";
import { COLOR_BLACK, COLOR_WHITE } from "../../../../../styles/constants";

export default makeStyles(theme => ({
  totalPriceBox: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[2],
    border: `1px solid ${theme.palette.grey[200]}`,
    backgroundColor: COLOR_WHITE,
    color: COLOR_BLACK,
    padding: theme.spacing(3, 4),
    width: "100%",
    maxWidth: 400,
    margin: '0 auto', 

    [theme.breakpoints.up("md")]: {
      maxWidth: 450,
        marginBottom: theme.spacing(4)
    },
  },
  checkoutButton: {
    marginTop: theme.spacing(3),
    width: "100%",
    fontSize: "1rem",
    textTransform: "none",
    fontWeight: 600,
  },
  crossIcon: { 
    width: 20,
    height: 20,
  },
  continueTxt: {
    textDecoration: "underline",
    color: "#0047FF",
    cursor: "pointer",
    textAlign: "center",
    fontSize: "0.9rem",
    '&:hover': {
        textDecoration: 'none'
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2), 
    },
  },
  priceContainer: {
    display: 'flex',
    flexDirection: 'row', 
    justifyContent: 'space-between', 
    alignItems: 'center', 
    marginBottom: theme.spacing(1),
    width: '100%',
  },
  totalLabel: {
    fontSize: '0.875rem',
    color: theme.palette.text.secondary,
  },
  totalPrice: {
    fontSize: '1.8rem',
    fontWeight: 700,
    lineHeight: 1.2,
  },
}));