import React from 'react';
import { Link } from 'react-router-dom';
import { WELCOME_URL } from '../routes/constants'; 
import { makeStyles } from '@material-ui/core/styles'; 
import { Box, Typography, Button } from '@material-ui/core'; 

const useStyles = makeStyles((theme) => ({
  noContentContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '50vh', 
    padding: theme.spacing(4),
  },
  title: {
    fontWeight: 'bold',
    fontSize: '2rem',
    marginBottom: theme.spacing(2),
  },
  message: {
    fontSize: '1.2rem',
    marginBottom: theme.spacing(3),
    color: theme.palette.text.secondary, 
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none', 
    '&:hover': {
        textDecoration: 'underline', 
    }


  },
  homeButton: { 
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding: theme.spacing(1, 2),
    borderRadius: theme.shape.borderRadius, 
    '&:hover': {
      backgroundColor: theme.palette.primary.dark, 
    },
  },

}));

const NoAppContent = () => {
  const classes = useStyles();
  return (
    <Box className={classes.noContentContainer}> 
      <Typography variant="h4" component="h1" className={classes.title}>
        No App Content Found
      </Typography>
      <Typography variant="body1" className={classes.message}>
        Please navigate to a specific app section.
      </Typography>
       <Button
          variant="contained"
          color="primary" 
          component={Link} 
          to={WELCOME_URL} 
          className={classes.homeButton}
        >
          Back to Home 
        </Button>

    </Box>
  );
};

export default NoAppContent;