import { PRIMARY_ACTIVE_LINK_STYLING } from "../../styles/constants";
import {
  MAIN_BAR_HEIGHT,
  TOP_BAR_HEIGHT,
  USER_TABS_BAR_HEIGHT,
} from "./constants";

const styles = theme => {
  const logoStyling = {
    width: "140px",
    height: "55px",
  };

  const activeTabLink = {
    color: theme.palette.primary.main,
    textDecoration: "none",
  };

  return {
    tabsBar: {
      [theme.breakpoints.up("sm")]: {
        backgroundColor: "#ededed",
      },
      width: "100vw",
      position: "sticky",
      height: USER_TABS_BAR_HEIGHT,
      marginTop: MAIN_BAR_HEIGHT,
    },
    tabsSelect: {
      width: "100%",
      marginTop: theme.spacing(2),
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
    arrowBox: {
      position: "relative",
      minHeight: USER_TABS_BAR_HEIGHT,
      "&::before": {
        content: "''",
        position: "absolute",
        width: 25,
        height: 25,
        background: "#ededed",
        boxSizing: "border-box",
        borderLeftColor: "transparent",
        borderTopColor: "transparent",
        bottom: -12.5,
        transform: "rotate(45deg)",
      },
    },
    tabLinkContainer: {
      borderRight: "1px solid rgba(0,0,0,.2)",
    },
    tabLink: {
      fontSize: "14pt",
      fontWeight: 600,
      color: "#4a4a4a",
      "&:hover, &:focus": {
        ...activeTabLink,
      },
    },
    activeTabLink: { ...activeTabLink },
    topBar: {
      height: TOP_BAR_HEIGHT,
      boxShadow: "none",
      backgroundColor: theme.palette.primary.main,
    },
    topBarIcon: {
      width: 15,
      height: 15,
    },
    phoneLabel: {
      height: 15,
    },
    appBar: {
      zIndex: 1000,
      boxShadow:
        "0 3px 8px rgb(61 74 84 / 10%), 0 3px 12px rgb(61 74 84 / 6%);",
      width: "100vw",
      position: "fixed",
      height: MAIN_BAR_HEIGHT,
      marginTop: props => (props.isCourseAppRoute ? 0 : TOP_BAR_HEIGHT),
    },
    logo: {
      ...logoStyling,
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
    homeLogo: { ...logoStyling },
    link: {
      fontSize: "1.2em",
      fontWeight: "bold",
      color: theme.palette.primary.main,
      "&:hover, &:focus": {
        ...PRIMARY_ACTIVE_LINK_STYLING,
      },
    },
    activeLink: {
      ...PRIMARY_ACTIVE_LINK_STYLING,
    },
    courseNavContentContainer: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    title: {
      fontSize: "2rem",
      fontFamily: "metropolis",
    },
    courseAppIconContainer: {
      display: "flex",
      alignItems: "center",
    },
    searchContainer: {
      position: "relative",
      borderRadius: "5px",
      backgroundColor: "rgb(238, 242, 245)",
      margin: theme.spacing(0, 2),
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        width: 300,
      },
      maxWidth: 300,
    },
    searchAutoComplete: {
      borderTopLeftRadius: "8px !important",
      borderTopRightRadius: "8px !important",
      borderBottomLeftRadius: "0 !important",
      borderBottomRightRadius: "0 !important",
      backgroundColor: "#fff !important",

      boxShadow:
        "rgb(79 95 103 / 36%) 0px 4px 8px 0px,rgb(79 95 103 / 24%) 0px 4px 12px 0px",
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: "100%",
      position: "absolute",
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    inputRoot: {
      color: "inherit",
      width: "100%",
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("md")]: {
        width: "20ch",
      },
    },
    // Auto complete styling
    autoCompleteListBoxCont: {
      padding: 0,
    },
    autoCompleteOptionCont: {
      padding: 0,
      '&[aria-selected="true"]': {
        backgroundColor: "#eef2f5",
      },
      '&[data-focus="true"]': {
        backgroundColor: "#eef2f5",
      },
      "&:active": {
        backgroundColor: "#eef2f5",
      },
    },
    autoCompleteOptionTxtCont: {
      padding: "10px 16px",
      [theme.breakpoints.down("xs")]: {
        padding: "10px 8px",
      },
      width: "100%",
      display: "flex",
      alignItems: "center",
    },
    autoCompleteEndAdornment: {
      marginRight: 8,
    },
    autoCompleteClearIndicator: {
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
    autoCompletePaper: {
      padding: "0 !important",
      backgroundColor: "#fff !important",
      borderTopLeftRadius: "0 !important",
      borderTopRightRadius: "0 !important",
      borderBottomLeftRadius: "8px",
      borderBottomRightRadius: "8px",
      border: "1px solid rgb(227, 230, 232)",
      boxShadow:
        "rgb(79 95 103 / 36%) 0px 10px 8px 0px,rgb(79 95 103 / 24%) 0px 10px 12px 0px",
    },
    appIconLink: {
      cursor: "pointer",
      fontSize: 21,
    },
    barsIcon: {
      fontSize: 15,
      color: "#fff",
    },
    barsIconContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      cursor: "pointer",
      borderRadius: "6em",
      width: "2.2em",
      height: "2.2em",
      backgroundColor: theme.palette.primary.main,
      color: "#fff",
      boxShadow: "0 1px 3px #888",
    },
    loginIcon: {
      cursor: "pointer",
      height: '22px',
      width: '22px',
      marginBottom: '2px',
      marginLeft: '-8px',
      color: theme.palette.primary.main, 
    },
    logoutIcon: {
      cursor: "pointer",
      height: '20px',
      width: '20px',
      marginBottom: '2px',
      color: theme.palette.primary.main, 
    },
  };
};

export default styles;
