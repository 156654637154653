import {
  COURSE_APP_SUB_ROUTE,
  MANAGE_MEDIA_URL,
  MANAGE_TRANSACTION_URL,
  WELCOME_URL,
  TOPICS_URL,
  CREATE_TEST_URL,
  MANAGE_DISCOUNT_CODE_URL,
  MANAGE_DELETED_ITEM_URL,
  STATISTICS_URL,
} from "../../routes/constants";

export const userMenuItemArr = [
  {
    imgPath: require("../../resources/assets/svg/home-w.svg"),
    title: "Welcome",
    alt: "Home Icon",
    route: WELCOME_URL,
  },
  {
    imgPath: require("../../resources/assets/svg/statistics-circle-chart-graph.svg"),
    title: "Statistics",
    alt: "Statistics Icon",
    style: { fill: 'rgb(153, 167, 179)' },
    route: STATISTICS_URL,
  },
  {
    imgPath: require("../../resources/assets/svg/topic.svg"),
    title: "Topics",
    alt: "Topic Icon",
    route: TOPICS_URL,
  },
  {
    imgPath: require("../../resources/assets/svg/create-w.svg"),
    title: "Create Test",
    alt: "Create Test Icon",
    route: CREATE_TEST_URL,
  },
  {
    imgPath: require("../../resources/assets/svg/prev-test-w.svg"),
    title: "Previous Test",
    alt: "Previous Test Icon",
    route: `${COURSE_APP_SUB_ROUTE}/test-list`,
  },
  {
    imgPath: require("../../resources/assets/svg/performance-w.svg"),
    title: "Performance",
    alt: "Performance Icon",
    subLink: [
      {
        imgPath: require("../../resources/assets/svg/clipboard-check.svg"),
        title: "Overall",
        alt: "Overall Performance Icon",
        route: `${COURSE_APP_SUB_ROUTE}/performance/overall`,
      },
      {
        imgPath: require("../../resources/assets/svg/pie-chart-w.svg"),
        title: "Reports",
        alt: "Report Icon",
        route: `${COURSE_APP_SUB_ROUTE}/performance/reports`,
      },
      {
        imgPath: require("../../resources/assets/svg/line-chart-w.svg"),
        title: "Graphs",
        alt: "Graph Icon",
        route: `${COURSE_APP_SUB_ROUTE}/performance/graphs`,
      },
    ],
  },
  {
    imgPath: require("../../resources/assets/svg/search-w.svg"),
    title: "Search",
    alt: "Search Icon",
    route: `${COURSE_APP_SUB_ROUTE}/search`,
  },
  {
    imgPath: require("../../resources/assets/svg/note-w.svg"),
    title: "Notes",
    alt: "Notes Icon",
    route: `${COURSE_APP_SUB_ROUTE}/notes`,
  },
  {
    imgPath: require("../../resources/assets/svg/flash-card-w.svg"),
    title: "Flashcards",
    alt: "Flascards Icon",
    route: `${COURSE_APP_SUB_ROUTE}/flashcards`,
  },
  {
    imgPath: require("../../resources/assets/svg/reset.svg"),
    title: "Reset",
    alt: "Reset Icon",
    route: `${COURSE_APP_SUB_ROUTE}/reset`,
  },
  {
    imgPath: require("../../resources/assets/svg/help-w.svg"),
    title: "Help",
    alt: "Help Icon",
    route: `${COURSE_APP_SUB_ROUTE}/help`,
  },
];

export const adminMenuItemArr = [
  {
    imgPath: require("../../resources/assets/svg/spreadsheet.svg"),
    title: "Import Data",
    alt: "Import Icon",
    route: `${COURSE_APP_SUB_ROUTE}/import-question`,
  },
  {
    imgPath: require("../../resources/assets/svg/image.svg"),
    title: "Manage Media",
    alt: "Media Icon",
    route: MANAGE_MEDIA_URL,
  },
  {
    imgPath: require("../../resources/assets/svg/topic.svg"),
    title: "Manage Topic",
    alt: "Topic Icon",
    route: `${COURSE_APP_SUB_ROUTE}/manage-topic`,
  },
  {
    imgPath: require("../../resources/assets/svg/subject.svg"),
    title: "Manage Category",
    alt: "Subject Icon",
    route: `${COURSE_APP_SUB_ROUTE}/manage-category`,
  },
  {
    imgPath: require("../../resources/assets/svg/users.svg"),
    title: "Manage User",
    alt: "User Icon",
    route: `${COURSE_APP_SUB_ROUTE}/manage-user`,
  },
  {
    imgPath: require("../../resources/assets/svg/question.svg"),
    title: "Manage Question",
    alt: "Question Icon",
    route: `${COURSE_APP_SUB_ROUTE}/manage-question`,
  },
  {
    title: "Manage Feedback",
    route: `${COURSE_APP_SUB_ROUTE}/manage-feedback`,
  },
  {
    title: "Manage Transaction",
    route: MANAGE_TRANSACTION_URL,
  },
  {
    title: "Manage Discount Code",
    route: MANAGE_DISCOUNT_CODE_URL,
  },
  {
    title: "Manage Deleted Item",
    route: MANAGE_DELETED_ITEM_URL,
  },
];
