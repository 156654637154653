import React, { useEffect } from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useSelector } from "react-redux";

import PlanBox from "../components/PlanBox";
import { PLAN_BOX_TYPE } from "../components/PlanBox/constants";
import TotalPriceBox from "../components/TotalPriceBox";
import { selectCartItems } from "../../../../selectors/cart";

const CartSection = props => {
  const { handleBack, handleNext, handleOpenRemoveModal } = props;
  const theme = useTheme();
  const lgUp = useMediaQuery(theme.breakpoints.up("lg"));
  const cartItems = useSelector(selectCartItems);

  useEffect(() => {
    if (!Boolean(cartItems.length)) {
      handleBack();
    }
  }, [cartItems, handleBack]);

  return (
    <>
      <Grid
        direction={lgUp ? "row" : "column"}
        justifyContent="space-between"
        container
      >
        <Grid item xs={12} lg={6}>
          <Grid direction="column" container >
            {cartItems.map((item, index) => (
              <Grid item key={index} xs={12} >
                <Box
                  display="flex"
                  justifyContent={lgUp ? "flex-end" : "center"}
                  alignItems={lgUp ? "" : "center"}
                >
                  <PlanBox
                    type={PLAN_BOX_TYPE.CART_ITEM}
                    pricePlan={item}
                    handleOpenRemoveModal={handleOpenRemoveModal}
                  />
                </Box>
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item xs={12} sm={lgUp ? 6 : 12}>
          <Box display="flex" justifyContent="center" alignItems="center" mt={!lgUp ? 5 : 0} >
            <TotalPriceBox
              cartItems={cartItems}
              handleBack={handleBack}
              handleNext={handleNext}
            />
          </Box>
        </Grid>
      </Grid>
      <Box mt={10} style={{ textAlign: 'center' }}>
        <Typography variant="subtitle2" component="p">
          Prices shown here do not include sales tax.
        </Typography>
        <Typography variant="subtitle2" component="p">
          *Group discount code can be applied at the time of payment.
        </Typography>
      </Box>
    </>
  );
};

export default CartSection;
